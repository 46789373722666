/*******************************
         Site Overrides
*******************************/

.ui.button.basic.link {
  box-shadow:none!important;
  padding-left:0;
  padding-right:0;

  &:hover, &:active, &:focus {
    box-shadow:none!important;
  }
}
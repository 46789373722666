/*******************************
         Site Overrides
*******************************/

body {
  color:#494949;
  height:100%; 
}

* {
  backface-visibility: hidden;
}

ul, ol {
  margin-left:0;
  padding-left:1.25em;
}

li {
  margin-bottom:0.5em;
}
/*******************************
         Site Overrides
*******************************/

.ui.header.sub {
  letter-spacing:0.15em;
}

.ui.header.sub.muted {
  color:#5D5D5D;
  font-size:0.75rem;
}

h4.ui.header,
h5.ui.header {
  font-weight:400;
}
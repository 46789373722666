/*******************************
    User Variable Overrides
*******************************/

/*--------------
   Font Files
---------------*/

@fontName: 'fa-solid-900';
@outlineFontName: 'fa-regular-400';
@brandFontName: 'fa-brands-400';


  /* Brand Icon Font Family */
  i.icon.\35 00px,
  i.icon.accessible.icon,
  i.icon.accusoft,
  i.icon.adn,
  i.icon.adversal,
  i.icon.affiliatetheme,
  i.icon.algolia,
  i.icon.amazon,
  i.icon.amazon.pay,
  i.icon.amilia,
  i.icon.android,
  i.icon.angellist,
  i.icon.angrycreative,
  i.icon.angular,
  i.icon.app.store,
  i.icon.app.store.ios,
  i.icon.apper,
  i.icon.apple,
  i.icon.apple.pay,
  i.icon.asymmetrik,
  i.icon.audible,
  i.icon.autoprefixer,
  i.icon.avianex,
  i.icon.aviato,
  i.icon.aws,
  i.icon.bandcamp,
  i.icon.behance,
  i.icon.behance.square,
  i.icon.bimobject,
  i.icon.bitbucket,
  i.icon.bitcoin,
  i.icon.bity,
  i.icon.black.tie,
  i.icon.blackberry,
  i.icon.blogger,
  i.icon.blogger.b,
  i.icon.bluetooth,
  i.icon.bluetooth.b,
  i.icon.btc,
  i.icon.buromobelexperte,
  i.icon.buysellads,
  i.icon.cc.amazon.pay,
  i.icon.cc.amex,
  i.icon.cc.apple.pay,
  i.icon.cc.diners.club,
  i.icon.cc.discover,
  i.icon.cc.jcb,
  i.icon.cc.mastercard,
  i.icon.cc.paypal,
  i.icon.cc.stripe,
  i.icon.cc.visa,
  i.icon.centercode,
  i.icon.chrome,
  i.icon.cloudscale,
  i.icon.cloudsmith,
  i.icon.cloudversify,
  i.icon.codepen,
  i.icon.codiepie,
  i.icon.connectdevelop,
  i.icon.contao,
  i.icon.cpanel,
  i.icon.creative.commons,
  i.icon.css3,
  i.icon.css3.alternate,
  i.icon.cuttlefish,
  i.icon.d.and.d,
  i.icon.dashcube,
  i.icon.delicious,
  i.icon.deploydog,
  i.icon.deskpro,
  i.icon.deviantart,
  i.icon.digg,
  i.icon.digital.ocean,
  i.icon.discord,
  i.icon.discourse,
  i.icon.dochub,
  i.icon.docker,
  i.icon.draft2digital,
  i.icon.dribbble,
  i.icon.dribbble.square,
  i.icon.dropbox,
  i.icon.drupal,
  i.icon.dyalog,
  i.icon.earlybirds,
  i.icon.edge,
  i.icon.elementor,
  i.icon.ember,
  i.icon.empire,
  i.icon.envira,
  i.icon.erlang,
  i.icon.ethereum,
  i.icon.etsy,
  i.icon.expeditedssl,
  i.icon.facebook,
  i.icon.facebook.f,
  i.icon.facebook.messenger,
  i.icon.facebook.square,
  i.icon.firefox,
  i.icon.first.order,
  i.icon.firstdraft,
  i.icon.flickr,
  i.icon.flipboard,
  i.icon.fly,
  i.icon.font.awesome,
  i.icon.font.awesome.alternate,
  i.icon.font.awesome.flag,
  i.icon.fonticons,
  i.icon.fonticons.fi,
  i.icon.fort.awesome,
  i.icon.fort.awesome.alternate,
  i.icon.forumbee,
  i.icon.foursquare,
  i.icon.free.code.camp,
  i.icon.freebsd,
  i.icon.get.pocket,
  i.icon.gg,
  i.icon.gg.circle,
  i.icon.git,
  i.icon.git.square,
  i.icon.github,
  i.icon.github.alternate,
  i.icon.github.square,
  i.icon.gitkraken,
  i.icon.gitlab,
  i.icon.gitter,
  i.icon.glide,
  i.icon.glide.g,
  i.icon.gofore,
  i.icon.goodreads,
  i.icon.goodreads.g,
  i.icon.google,
  i.icon.google.drive,
  i.icon.google.play,
  i.icon.google.plus,
  i.icon.google.plus.g,
  i.icon.google.plus.square,
  i.icon.google.wallet,
  i.icon.gratipay,
  i.icon.grav,
  i.icon.gripfire,
  i.icon.grunt,
  i.icon.gulp,
  i.icon.hacker.news,
  i.icon.hacker.news.square,
  i.icon.hips,
  i.icon.hire.a.helper,
  i.icon.hooli,
  i.icon.hotjar,
  i.icon.houzz,
  i.icon.html5,
  i.icon.hubspot,
  i.icon.imdb,
  i.icon.instagram,
  i.icon.internet.explorer,
  i.icon.ioxhost,
  i.icon.itunes,
  i.icon.itunes.note,
  i.icon.jenkins,
  i.icon.joget,
  i.icon.joomla,
  i.icon.js,
  i.icon.js.square,
  i.icon.jsfiddle,
  i.icon.keycdn,
  i.icon.kickstarter,
  i.icon.kickstarter.k,
  i.icon.korvue,
  i.icon.laravel,
  i.icon.lastfm,
  i.icon.lastfm.square,
  i.icon.leanpub,
  i.icon.less,
  i.icon.linechat,
  i.icon.linkedin,
  i.icon.linkedin.alternate,
  i.icon.linkedin.in,
  i.icon.linode,
  i.icon.linux,
  i.icon.lyft,
  i.icon.magento,
  i.icon.maxcdn,
  i.icon.medapps,
  i.icon.medium,
  i.icon.medium.m,
  i.icon.medrt,
  i.icon.meetup,
  i.icon.microsoft,
  i.icon.mix,
  i.icon.mixcloud,
  i.icon.mizuni,
  i.icon.modx,
  i.icon.monero,
  i.icon.napster,
  i.icon.nintendo.switch,
  i.icon.node,
  i.icon.node.js,
  i.icon.npm,
  i.icon.ns8,
  i.icon.nutritionix,
  i.icon.odnoklassniki,
  i.icon.odnoklassniki.square,
  i.icon.opencart,
  i.icon.openid,
  i.icon.opera,
  i.icon.optin.monster,
  i.icon.osi,
  i.icon.page4,
  i.icon.pagelines,
  i.icon.palfed,
  i.icon.patreon,
  i.icon.paypal,
  i.icon.periscope,
  i.icon.phabricator,
  i.icon.phoenix.framework,
  i.icon.php,
  i.icon.pied.piper,
  i.icon.pied.piper.alternate,
  i.icon.pied.piper.pp,
  i.icon.pinterest,
  i.icon.pinterest.p,
  i.icon.pinterest.square,
  i.icon.playstation,
  i.icon.product.hunt,
  i.icon.pushed,
  i.icon.python,
  i.icon.qq,
  i.icon.quinscape,
  i.icon.quora,
  i.icon.ravelry,
  i.icon.react,
  i.icon.rebel,
  i.icon.redriver,
  i.icon.reddit,
  i.icon.reddit.alien,
  i.icon.reddit.square,
  i.icon.rendact,
  i.icon.renren,
  i.icon.replyd,
  i.icon.resolving,
  i.icon.rocketchat,
  i.icon.rockrms,
  i.icon.safari,
  i.icon.sass,
  i.icon.schlix,
  i.icon.scribd,
  i.icon.searchengin,
  i.icon.sellcast,
  i.icon.sellsy,
  i.icon.servicestack,
  i.icon.shirtsinbulk,
  i.icon.simplybuilt,
  i.icon.sistrix,
  i.icon.skyatlas,
  i.icon.skype,
  i.icon.slack,
  i.icon.slack.hash,
  i.icon.slideshare,
  i.icon.snapchat,
  i.icon.snapchat.ghost,
  i.icon.snapchat.square,
  i.icon.soundcloud,
  i.icon.speakap,
  i.icon.spotify,
  i.icon.stack.exchange,
  i.icon.stack.overflow,
  i.icon.staylinked,
  i.icon.steam,
  i.icon.steam.square,
  i.icon.steam.symbol,
  i.icon.sticker.mule,
  i.icon.strava,
  i.icon.stripe,
  i.icon.stripe.s,
  i.icon.studiovinari,
  i.icon.stumbleupon,
  i.icon.stumbleupon.circle,
  i.icon.superpowers,
  i.icon.supple,
  i.icon.telegram,
  i.icon.telegram.plane,
  i.icon.tencent.weibo,
  i.icon.themeisle,
  i.icon.trello,
  i.icon.tripadvisor,
  i.icon.tumblr,
  i.icon.tumblr.square,
  i.icon.twitch,
  i.icon.twitter,
  i.icon.twitter.square,
  i.icon.typo3,
  i.icon.uber,
  i.icon.uikit,
  i.icon.uniregistry,
  i.icon.untappd,
  i.icon.usb,
  i.icon.ussunnah,
  i.icon.vaadin,
  i.icon.viacoin,
  i.icon.viadeo,
  i.icon.viadeo.square,
  i.icon.viber,
  i.icon.vimeo,
  i.icon.vimeo.square,
  i.icon.vimeo.v,
  i.icon.vine,
  i.icon.vk,
  i.icon.vnv,
  i.icon.vuejs,
  i.icon.wechat,
  i.icon.weibo,
  i.icon.weixin,
  i.icon.whatsapp,
  i.icon.whatsapp.square,
  i.icon.whmcs,
  i.icon.wikipedia.w,
  i.icon.windows,
  i.icon.wordpress,
  i.icon.wordpress.simple,
  i.icon.wpbeginner,
  i.icon.wpexplorer,
  i.icon.wpforms,
  i.icon.xbox,
  i.icon.xing,
  i.icon.xing.square,
  i.icon.y.combinator,
  i.icon.yahoo,
  i.icon.yandex,
  i.icon.yandex.international,
  i.icon.yelp,
  i.icon.yoast,
  i.icon.youtube,
  i.icon.youtube.square {
    font-family: @brandFontName!important;
  }